// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

export const BackgroundWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   height: 100%;
   position: absolute;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   overflow: hidden;
   z-index: -1;
   &::after {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.5;
      background-color: #151515;
   }
   & > div {
      height: 100%;
   }
`;
