// @flow
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

const Heading: StyledComponent<
   {|
      children: string,
      theme: 'light' | 'dark',
   |},
   void,
   HTMLHeadingElement,
> = styled.h2`
   @media ${queries.desktop} {
      font-size: 52px;
   }
   @media ${queries.tablet} {
      font-size: 44px;
   }
   font-size: 34px;
   color: ${({ theme }) => (theme === 'light' ? '#fff' : '#000')};
   font-weight: 700;
   line-height: 1.2;
`;

export default Heading;
