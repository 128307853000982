// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';
import Text from '#/Text';

export const AboutWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.tabletPlus} {
      padding-top: 200px;
      margin-bottom: 300px;
   }
   padding-top: 70px;
   background-color: #fff;
   margin-bottom: 200px;
`;

export const AboutContent: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.tabletPlus} {
      grid-template-columns: auto minmax(auto, 600px);
      grid-gap: 0 50px;
   }
   grid-template-columns: 1fr;
   display: grid;
   grid-gap: 70px 0;
   align-items: center;
   grid-column-start: 2;
   grid-column-end: 3;
   @media ${queries.mobile} {
      ${Text} {
         text-align: center;
      }
   }
   em {
      font-style: normal;
      font-weight: 700;
   }
`;
