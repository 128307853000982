// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import Container from '#/Container';
import { StyledLocation } from '#/Location/style';
import queries from '%/mediaQueries';
import { c1 } from '%/colors';

export const HeaderWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   any,
> = styled.header`
   @media ${queries.tabletPlus} {
      padding-top: 60px;
   }
   padding-top: 20px;
   width: 100%;
   position: relative;
   @media ${queries.mobile} {
      position: absolute;
      top: 0;
      left: 0;
   }
   ${Container} {
      @media ${queries.mobile} {
         display: block;
         padding: 0 15px;
      }
   }
`;

export const HeaderContent: StyledComponent<
   {|
      children: Node,
      active: boolean,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.tabletPlus} {
      grid-column-start: 2;
      grid-column-end: 3;
      display: flex;

      align-items: center;
      justify-content: space-between;
      & > svg {
         @media ${queries.desktop} {
            margin-bottom: 60px;
            width: 200px;
         }
         @media ${queries.tablet} {
            width: 140px;
            margin-bottom: 30px;
         }
      }
   }
   @media ${queries.mobile} {
      position: fixed;
      width: 100%;
      min-width: 360px;
      min-height: 100vh;
      background-color: ${c1};
      transition: transform 0.2s;
      overflow-y: scroll;
      top: 0;
      right: 100%;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: ${({ active }) =>
         active ? 'translate(100%, 0)' : 'translate(0, 0)'};
      z-index: 5;
      will-change: transform;
      padding: 100px 0;
      & > svg {
         width: 150px;
         flex-shrink: 0;
         margin-bottom: 100px;
      }
      ${StyledLocation} {
         order: 4;
         margin-top: 70px;
      }
   }
`;

// export const Location: StyledComponent<
//    {|
//       children: string,
//    |},
//    void,
//    HTMLSpanElement,
// > = styled.span`
//    @media ${queries.tablet} {
//       font-size: 14px;
//    }
//    font-size: 16px;
//    color: ${c2};
//    line-height: 1.4;
//    font-weight: 700;
//    display: inline-block;
//    @media ${queries.mobile} {

//    }
// `;
