// @flow
import React from 'react';
import GatsbyImg from 'gatsby-image';

import { StyledImage } from './style';

type Props = {|
   start: number,
   end: number,
   image: any,
|};

const LandingImage = ({ start, end, image }: Props) => (
   <StyledImage start={start} end={end}>
      <GatsbyImg fluid={image.childImageSharp.fluid} />
   </StyledImage>
);

export default LandingImage;
