// @flow
import React from 'react';

import Container from '#/Container';
import Background from './Background/Background';
import Images from './Images/Images';
import Content from './Content/Content';
import { LandingWrapper } from './style';

const Landing = () => (
   <LandingWrapper>
      <Background />
      <Container>
         <Content />
         <Images />
      </Container>
   </LandingWrapper>
);

export default Landing;
