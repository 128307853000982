// @flow
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

const Text: StyledComponent<
   {|
      children: string,
   |},
   void,
   HTMLParagraphElement,
> = styled.p`
   @media ${queries.desktop} {
      font-size: 18px;
   }
   font-size: 16px;
   line-height: 1.6;
`;

export default Text;
