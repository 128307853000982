// @flow
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Partner from './Partner';
import { Partners } from './style';

const FooterPartners = () => {
   const { allPartnersJson: data } = useStaticQuery(graphql`
      {
         allPartnersJson {
            edges {
               node {
                  id
                  name
                  spec
               }
            }
         }
      }
   `);
   return (
      <Partners>
         {data.edges.map(({ node }) => (
            <Partner key={node.id} name={node.name} spec={node.spec} />
         ))}
      </Partners>
   );
};

export default FooterPartners;
