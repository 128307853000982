// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import Heading from '#/Heading/StyledHeading';
import queries from '%/mediaQueries';

export const EventsWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   position: relative;
`;

export const EventBackground: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: -1;
`;

export const EventsContent: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.tabletPlus} {
      padding-bottom: 250px;
   }
   padding-bottom: 80px;
   padding-top: 120px;
   grid-column-start: 2;
   grid-column-end: 3;
   ${Heading} {
      @media ${queries.mobile} {
         display: none;
      }
   }
`;

export const EventsList: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLUListElement,
> = styled.ul`
   list-style: none;
   @media ${queries.tabletPlus} {
      margin-top: 150px;
   }
   margin-top: 50px;
`;
