// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import { c1 } from '%/colors';
import queries from '%/mediaQueries';

export const BackgroundWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.tabletPlus} {
      height: 850px;
   }
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   overflow: hidden;
   z-index: -1;
   &::after {
      @media ${queries.tabletPlus} {
         background-color: ${c1};
         opacity: 0.95;
      }
      opacity: 0.5;
      background-color: #070000;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
   }
   & > div {
      @media ${queries.tabletPlus} {
         opacity: 0.5;
      }
      height: 100%;
   }
`;
