// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export const Partners: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.desktop} {
      grid-template-columns: auto auto auto;
      grid-gap: 110px 30px;
   }
   @media ${queries.tablet} {
      grid-template-columns: 1fr 1fr;
   }
   @media ${queries.tabletPlus} {
      margin-top: 200px;
   }
   grid-template-columns: 1fr;
   margin-top: 100px;
   display: grid;
   grid-gap: 90px 30px;
`;

export const PartnerWrapper: StyledComponent<
   {|
      children: Node,
      multiple: boolean,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   color: #fff;
   text-align: center;
   @media ${queries.tabletPlus} {
      text-align: left;
   }

   h6 {
      @media ${queries.desktop} {
         font-size: ${({ multiple }) => (multiple ? '26px' : '32px')};
      }
      @media ${queries.tablet} {
         font-size: ${({ multiple }) => (multiple ? '22px' : '28px')};
      }
      font-size: ${({ multiple }) => (multiple ? '20px' : '24px')};
      margin-top: 15px;
   }
   span {
      display: block;
      font-size: 16px;
      padding-bottom: 5px;
   }
`;
