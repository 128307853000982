// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

export const StyledImage: StyledComponent<
   {|
      children: Node,
      start: number,
      end: number,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   grid-row-start: ${({ start }) => start};
   grid-row-end: ${({ end }) => end};
   position: relative;
   & > div {
      height: 100%;
   }
   img {
      height: 100%;
   }
   &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.1;
   }
`;
