// @flow
import React from 'react';

import Heading from '#/Heading/StyledHeading';
import Container from '#/Container';
import { ContactUpper, ContactUpperContent as Content } from './style';
import Card from './Card/Card';
import Map from './Map/Map';

const Contact = () => (
   <>
      <ContactUpper id='contact'>
         <Container>
            <Content>
               <Heading left top hash theme='dark'>
                  Hned vedle nejrušnější ulice, a přece v klidu
               </Heading>
               <Card />
            </Content>
         </Container>
      </ContactUpper>
      <Map />
   </>
);

export default Contact;
