// @flow
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import axios from 'axios';

import queries from '%/mediaQueries';
import Image from './Image/Image';
import { ImageWrapper } from './style';

const FoodImages = () => {
   const [concepts, setConcepts] = useState({});

   useEffect(() => {
      axios('https://api.github.com/gists/bb471534aa3119864e6a6a054f1f96d5')
         .then(({ data }) => {
            const json = data.files['concepts.json'];
            setConcepts(JSON.parse(json.content));
         })
         .catch(() => {});
   }, []);

   const {
      image1,
      image1small,
      image2,
      image2small,
      image3,
      image3small,
      image4,
      image4small,
      image6,
      image6small,
   } = useStaticQuery(graphql`
      query {
         image1: file(relativePath: { eq: "meat_beer.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 60) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image1small: file(relativePath: { eq: "meat_beer-small.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 60) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image2: file(relativePath: { eq: "woke_bowl.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 60) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image2small: file(relativePath: { eq: "woke_bowl-small.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 60) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image3: file(relativePath: { eq: "josper.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 50) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image3small: file(relativePath: { eq: "josper-small.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 50) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image4: file(relativePath: { eq: "la_patrona.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 60) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image4small: file(relativePath: { eq: "la_patrona-small.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 60) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image6: file(relativePath: { eq: "vinohradsky_pivovar.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 60) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image6small: file(
            relativePath: { eq: "vinohradsky_pivovar-small.jpg" }
         ) {
            childImageSharp {
               fluid(maxWidth: 320, quality: 60) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `);
   const sources1 = [
      {
         ...image1.childImageSharp.fluid,
         media: queries.tabletPlus,
      },
      {
         ...image1small.childImageSharp.fluid,
         media: queries.mobile,
      },
   ];
   const sources2 = [
      {
         ...image2.childImageSharp.fluid,
         media: queries.tabletPlus,
      },
      {
         ...image2small.childImageSharp.fluid,
         media: queries.mobile,
      },
   ];
   const sources3 = [
      {
         ...image3.childImageSharp.fluid,
         media: queries.tabletPlus,
      },
      {
         ...image3small.childImageSharp.fluid,
         media: queries.mobile,
      },
   ];
   const sources4 = [
      {
         ...image4.childImageSharp.fluid,
         media: queries.tabletPlus,
      },
      {
         ...image4small.childImageSharp.fluid,
         media: queries.mobile,
      },
   ];
   const sources6 = [
      {
         ...image6.childImageSharp.fluid,
         media: queries.tabletPlus,
      },
      {
         ...image6small.childImageSharp.fluid,
         media: queries.mobile,
      },
   ];
   return (
      <ImageWrapper>
         <Image
            href='/menus/Zahrada_Savarin_Meat_Beer_Menu.pdf'
            image={sources1}
            text='Meat Beer'
            gridName='A'
            hours={concepts.meatBeer || ''}
         />
         <Image
            href='/menus/Zahrada_Savarin_Josper_Grill_Menu.pdf'
            image={sources3}
            text='Josper Grill'
            gridName='E'
            hours={concepts.vycep || ''}
         />
         <Image
            href='/menus/Zahrada_Savarin_Woke_Bowl_Menu.pdf'
            image={sources2}
            text='Woké Bowl'
            gridName='C'
            hours={concepts.wokePoke || ''}
         />
         <Image
            href='/menus/Zahrada_Savarin_La_Patrona_Menu.pdf'
            image={sources4}
            text='La Patrona'
            gridName='D'
            hours={concepts.laPatrona || ''}
         />
         <Image
            href='/menus/Zahrada_Savarin_Vinohradsky_Pivovar_Menu.pdf'
            image={sources6}
            text='Zahradní bar'
            gridName='F'
            hours={concepts.vinohradskyPivovar || ''}
         />
      </ImageWrapper>
   );
};

export default FoodImages;
