// @flow
import React from 'react';

const SiteLogoInline = () => (
   <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666.513 52.003'>
      <g fill='#fcfdfe'>
         <path d='M28.845 34.479h1.126l-.8 13.124H.005L25.097 4.18H14.213c-5.362 0-9.329 4.536-10.134 12.277H2.793l.8-13.063h28.684L7.024 46.82h11.849c6.916 0 9.221-6.837 9.972-12.341z' />
         <path d='M73.665 46.817v.786H60.1v-.786c2.842 0 4.182-2.177 3.217-5.443l-3.539-10.16H45.999l-1.823 5.322c-3 8.406 2.145 10.281 4.665 10.281v.786h-13.35v-.786c4.128 0 5.737-4.838 6.97-8.164.375-1.028 10.08-28.424 9.811-28.969a3.565 3.565 0 00-2.52-2.3 11.84 11.84 0 006.273-4.052s13.082 37.677 13.189 37.919c1.88 4.836 2.363 5.566 4.451 5.566zM59.51 30.307l-6.595-19.05-6.648 19.05z' />
         <path d='M109.913 8.595v33.807c0 3.749 2.52 4.415 4.128 4.415v.786h-13.672v-.786c2.091 0 4.075-.544 4.075-4.355v-16.51H86.537v16.51c0 3.81 2.037 4.355 4.128 4.355v.786H76.886v-.786c1.662 0 4.182-.666 4.182-4.415V8.656c0-3.75-2.52-4.476-4.182-4.476v-.786h13.775v.786c-2.091 0-4.128.545-4.128 4.415v16.511h17.907V8.535c0-3.81-2.091-4.355-4.182-4.355v-.786h13.672v.786c-1.604 0-4.017.666-4.017 4.415z' />
         <path d='M156.239 46.82v.786h-11.206L132.755 26.56h-5.2v15.845c0 4.657 3.7 4.415 5.2 4.415v.786H117.26v-.786c1.34 0 4.718.181 4.718-4.355V25.77h10.294L121.013 6.478s-1.394-2.3-3.7-2.3v-.786h14.9c4.558 0 13.994 1.089 13.994 11.491 0 6.35-4.718 9.616-7.667 10.886 4.45 7.741 10.187 18.748 15.87 20.683a10.715 10.715 0 001.829.368zm-16.513-31.932c0-6.108-1.555-10.7-7.506-10.7h-6.058l10.991 19.172c1.554-1.52 2.573-3.879 2.573-8.475z' />
         <path d='M197.631 46.817v.786h-13.565v-.786c2.842 0 4.182-2.177 3.217-5.443l-3.539-10.16h-13.779l-1.823 5.322c-3 8.406 2.145 10.281 4.665 10.281v.786h-13.35v-.786c4.128 0 5.737-4.838 6.97-8.164.375-1.028 10.08-28.424 9.812-28.969a3.567 3.567 0 00-2.52-2.3 11.84 11.84 0 006.273-4.052s13.082 37.677 13.189 37.919c1.877 4.836 2.359 5.566 4.45 5.566zm-14.154-16.51l-6.6-19.05-6.648 19.05z' />
         <path d='M236.398 24.984c0 22.2-13.618 22.619-20.963 22.619h-14.584v-.786c2.68 0 4.825-1.21 4.825-4.657V8.66c0-2.843-1.233-4.476-4.771-4.476v-.786h17.532a17.345 17.345 0 013.056.242c6.273.964 14.905 5.137 14.905 21.344zm-6.809.423c-.161-4.838-.59-9.736-2.573-13.97a11.332 11.332 0 00-6.327-6.168 14.815 14.815 0 00-5.04-1.089c-2.466 0-4.45.484-4.45 4.053v34.29c0 4.052 1.555 4.233 4.236 4.233a13.265 13.265 0 0010.615-5.14c2.198-2.843 3.807-9.196 3.534-16.208z' />
         <path d='M272.428 46.817v.786h-13.565v-.786c2.842 0 4.182-2.177 3.217-5.443l-3.541-10.16h-13.776l-1.824 5.322c-3 8.406 2.145 10.281 4.664 10.281v.786h-13.349v-.786c4.128 0 5.737-4.838 6.97-8.164.375-1.028 10.08-28.424 9.811-28.969a3.565 3.565 0 00-2.52-2.3 11.84 11.84 0 006.273-4.052s13.082 37.677 13.19 37.919c1.876 4.836 2.361 5.566 4.45 5.566zm-14.155-16.51l-6.594-19.05-6.648 19.05z' />
         <path d='M320.88 45.686c-7.506 5.019-18.283-.484-18.283-.484l.751-9.495h.643c.375 9.374 8.257 12.337 11.8 11.007 3.7-1.391 4.879-7.62 2.734-11.248-2.359-3.931-6.809-6.35-10.509-8.649a12.994 12.994 0 01-5.79-10.765q2.494.816 4.986 1.452c1.019 2.479 3.164 4.173 5.308 5.5 2.842 1.754 5.629 3.387 7.989 5.987 5.036 5.386 4.5 13.913.371 16.695zm4.665-15.724c-2.359-3.991-6.863-6.35-10.509-8.709a12.686 12.686 0 01-5.79-10.826 8.909 8.909 0 015.415-7.559c7.023-2.843 15.28 1.633 15.28 1.633l-.7 9.495h-.7c-.59-10.1-9.329-12.64-12.707-10.644-2.145 1.33-2.734 4.9-2.037 7.5.8 3.145 3.271 5.141 5.737 6.653 2.842 1.754 5.63 3.386 7.989 5.927a13.625 13.625 0 013.485 11.007l-4.45-1.33a8.518 8.518 0 00-1.013-3.147z' />
         <path d='M370.905 46.352v.786H357.34v-.786c2.842 0 4.182-2.177 3.217-5.443l-3.538-10.16H343.24l-1.823 5.322c-3 8.406 2.145 10.281 4.664 10.281v.786h-13.352v-.786c4.128 0 5.737-4.838 6.97-8.164.375-1.028 10.08-28.424 9.811-28.969a3.567 3.567 0 00-2.52-2.3 11.841 11.841 0 006.273-4.052s13.082 37.677 13.19 37.919c1.876 4.84 2.361 5.566 4.452 5.566zm-14.155-16.51l-6.595-19.05-6.648 19.05z' />
         <path d='M401.627 2.929v.786c-4.128 0-5.737 4.838-7.024 8.165l-12.921 37.193-14.1-40.64s-1.286-4.536-4.182-4.536v-.968h13.564v.968c-4.182 0-3.967 3.326-2.949 6.108l10.348 29.21 8.578-24.917c3-8.406-2.145-10.583-4.718-10.583v-.786z' />
         <path d='M431.224 46.352v.786H417.66v-.786c2.842 0 4.182-2.177 3.217-5.443l-3.539-10.16h-13.779l-1.823 5.322c-3 8.406 2.145 10.281 4.664 10.281v.786h-13.35v-.786c4.128 0 5.737-4.838 6.97-8.164.376-1.028 10.08-28.424 9.812-28.969a3.567 3.567 0 00-2.52-2.3 11.841 11.841 0 006.273-4.052s13.082 37.677 13.189 37.919c1.877 4.84 2.358 5.566 4.45 5.566zm-14.154-16.51l-6.595-19.05-6.648 19.05z' />
         <path d='M473.427 46.351v.786h-11.211l-12.278-21.046h-5.2v15.844c0 4.657 3.7 4.415 5.2 4.415v.786h-15.494v-.786c1.34 0 4.718.182 4.718-4.355v-16.69h10.294L438.197 6.013s-1.394-2.3-3.7-2.3v-.786h14.905c4.557 0 13.993 1.088 13.993 11.491 0 6.35-4.718 9.616-7.667 10.886 4.45 7.741 10.187 18.748 15.87 20.683a10.765 10.765 0 001.829.364zm-16.513-31.932c0-6.109-1.555-10.7-7.506-10.7h-6.059L454.34 22.89c1.55-1.515 2.569-3.874 2.569-8.47z' />
         <path d='M486.131 41.997c0 3.75 1.983 4.355 4.074 4.355v.786h-13.564v-.786c2.091 0 4.021-.6 4.021-4.355V8.07c0-3.75-1.93-4.355-4.021-4.355v-.786h13.564v.786c-2.091 0-4.074.6-4.074 4.355z' />
         <path d='M532.347 2.929v.847c-2.412 0-6.541 2-6.541 8.829v34.53h-1.823L500.66 10.668v26.791c0 6.834 4.129 8.829 6.542 8.829v.847H493.42v-.847c2.574 0 6.541-1.814 6.541-8.769V9.639l-1.286-2.056s-2.252-3.629-5.093-3.629V2.926h9.438l22.143 33.927V12.545c0-6.955-4.074-8.769-6.648-8.769v-.847z' />
         <path d='M610.236 32.898l-16.04-16.04 16.04-16.04 16.04 16.04zm-12.362-16.04l12.362 12.363 12.363-12.363-12.363-12.362z' />
         <path d='M610.236 42.86l-16.04-16.04 16.04-16.04 16.04 16.04zm-12.362-16.04l12.362 12.362 12.363-12.362-12.363-12.362z' />
         <path d='M608.936 2.657h2.601v49.347h-2.601z' />
         <path d='M570.068 19.515l16.04 16.04-16.04 16.04-16.04-16.04zm12.362 16.04l-12.362-12.362-12.361 12.362 12.362 12.362z' />
         <path d='M570.068 9.553l16.039 16.038-16.04 16.04-16.04-16.04zm12.362 16.04l-12.362-12.362-12.361 12.36 12.362 12.362z' />
         <path d='M568.768.409h2.601v49.347h-2.601z' />
         <path d='M650.473 19.106l16.04 16.04-16.04 16.04-16.04-16.04zm12.362 16.04l-12.362-12.362-12.362 12.362 12.362 12.362z' />
         <path d='M650.473 9.144l16.04 16.04-16.04 16.04-16.04-16.04zm12.366 16.04l-12.362-12.363-12.366 12.363 12.362 12.36z' />
         <path d='M649.173 0h2.601v49.347h-2.601z' />
      </g>
   </svg>
);

export default SiteLogoInline;
