// @flow
import React from 'react';

const SiteLogo = () => (
   <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 239.404 153.096'>
      <g fill='#fff'>
         <path d='M25.348 95.714h.989l-.707 11.533H-.001l22.05-38.159h-9.564c-4.712 0-8.2 3.986-8.905 10.789H2.45l.707-11.48h25.207L6.173 106.556h10.412c6.078 0 8.104-6.006 8.763-10.842z' />
         <path d='M64.738 106.556v.691h-11.92v-.691c2.5 0 3.675-1.913 2.827-4.783l-3.11-8.928H40.426l-1.6 4.677c-2.638 7.387 1.885 9.035 4.1 9.035v.691H31.194v-.691c3.628 0 5.041-4.252 6.125-7.175.33-.9 8.858-24.979 8.622-25.457a3.133 3.133 0 00-2.214-2.019 10.4 10.4 0 005.513-3.561s11.5 33.11 11.59 33.322c1.647 4.251 2.071 4.889 3.908 4.889zM52.299 92.047l-5.8-16.741-5.836 16.741z' />
         <path d='M96.59 72.968v29.709c0 3.295 2.215 3.88 3.628 3.88v.691H88.203v-.691c1.838 0 3.581-.478 3.581-3.827V88.223H76.047v14.508c0 3.348 1.79 3.827 3.628 3.827v.691H67.566v-.691c1.46 0 3.675-.585 3.675-3.88V73.023c0-3.3-2.215-3.933-3.675-3.933v-.691h12.109v.691c-1.838 0-3.628.479-3.628 3.88v14.509h15.737V72.917c0-3.348-1.838-3.827-3.675-3.827v-.691h12.015v.691c-1.414-.002-3.534.583-3.534 3.878z' />
         <path d='M137.3 106.556v.691h-9.847l-10.789-18.495h-4.57v13.924c0 4.092 3.251 3.88 4.57 3.88v.691h-13.616v-.691c1.178 0 4.146.159 4.146-3.827V88.061h9.046l-9.894-16.953a4.3 4.3 0 00-3.251-2.02v-.691h13.1c4.005 0 12.3.957 12.3 10.1 0 5.581-4.146 8.45-6.738 9.566 3.911 6.8 8.952 16.475 13.946 18.176a9.417 9.417 0 001.597.317zm-14.512-28.061c0-5.368-1.366-9.407-6.6-9.407h-5.317l9.659 16.848c1.363-1.329 2.258-3.402 2.258-7.441z' />
         <path d='M173.674 106.556v.691h-11.92v-.691c2.5 0 3.675-1.913 2.827-4.783l-3.11-8.928H149.36l-1.6 4.677c-2.638 7.387 1.885 9.035 4.1 9.035v.691h-11.732v-.691c3.628 0 5.041-4.252 6.125-7.175.33-.9 8.858-24.979 8.622-25.457a3.134 3.134 0 00-2.215-2.019 10.4 10.4 0 005.513-3.561s11.5 33.11 11.59 33.322c1.65 4.251 2.074 4.889 3.911 4.889zm-12.438-14.509l-5.8-16.741-5.842 16.741z' />
         <path d='M207.737 87.37c0 19.5-11.967 19.877-18.422 19.877h-12.811v-.691c2.355 0 4.24-1.063 4.24-4.093v-29.44c0-2.5-1.084-3.933-4.193-3.933v-.691h15.407a15.234 15.234 0 012.686.213c5.512.848 13.093 4.511 13.093 18.758zm-5.984.372c-.141-4.252-.518-8.556-2.261-12.277a9.958 9.958 0 00-5.56-5.421 13.018 13.018 0 00-4.429-.957c-2.167 0-3.911.425-3.911 3.561v30.133c0 3.561 1.366 3.72 3.722 3.72a11.657 11.657 0 009.329-4.517c1.937-2.496 3.351-8.077 3.115-14.242z' />
         <path d='M239.404 106.556v.691h-11.92v-.691c2.5 0 3.675-1.913 2.827-4.783l-3.11-8.928h-12.108l-1.6 4.677c-2.638 7.387 1.885 9.035 4.1 9.035v.691h-11.735v-.691c3.628 0 5.041-4.252 6.125-7.175.33-.9 8.858-24.979 8.622-25.457a3.133 3.133 0 00-2.214-2.019 10.4 10.4 0 005.512-3.561s11.5 33.11 11.591 33.322c1.649 4.251 2.073 4.889 3.91 4.889zm-12.438-14.509l-5.8-16.741-5.838 16.741z' />
         <path d='M32.707 150.12c-6.6 4.411-16.066-.425-16.066-.425l.66-8.344h.565c.33 8.238 7.256 10.842 10.365 9.673 3.251-1.223 4.288-6.7 2.4-9.885-2.073-3.455-5.984-5.581-9.235-7.6a11.419 11.419 0 01-5.088-9.46q2.191.717 4.382 1.275a10.915 10.915 0 004.665 4.836 30.371 30.371 0 017.02 5.261c4.432 4.731 3.96 12.225.332 14.669zm4.1-13.817c-2.073-3.508-6.031-5.581-9.235-7.653a11.148 11.148 0 01-5.089-9.513 7.83 7.83 0 014.759-6.643c6.172-2.5 13.428 1.435 13.428 1.435l-.613 8.344h-.612c-.518-8.875-8.2-11.107-11.167-9.354-1.885 1.169-2.4 4.305-1.79 6.59.707 2.763 2.874 4.518 5.041 5.846a31.69 31.69 0 017.02 5.208 11.974 11.974 0 013.063 9.672l-3.911-1.169a7.487 7.487 0 00-.894-2.763z' />
         <path d='M76.667 150.705v.691h-11.92v-.691c2.5 0 3.675-1.913 2.827-4.783l-3.11-8.928H52.355l-1.6 4.677c-2.638 7.387 1.885 9.035 4.1 9.035v.691H43.123v-.691c3.628 0 5.041-4.252 6.125-7.175.33-.9 8.858-24.979 8.622-25.457a3.133 3.133 0 00-2.214-2.019 10.408 10.408 0 005.506-3.564s11.5 33.11 11.59 33.322c1.654 4.254 2.078 4.892 3.915 4.892zm-12.439-14.509l-5.8-16.741-5.842 16.741z' />
         <path d='M103.666 112.546v.691c-3.628 0-5.041 4.252-6.172 7.175l-11.355 32.685-12.392-35.714s-1.13-3.986-3.675-3.986v-.851h11.92v.851c-3.675 0-3.486 2.923-2.591 5.368l9.094 25.669 7.538-21.9c2.639-7.387-1.885-9.3-4.146-9.3v-.691z' />
         <path d='M129.676 150.705v.691h-11.922v-.691c2.5 0 3.675-1.913 2.827-4.783l-3.11-8.928h-12.107l-1.6 4.677c-2.639 7.387 1.885 9.035 4.1 9.035v.691H96.132v-.691c3.628 0 5.041-4.252 6.125-7.175.33-.9 8.858-24.979 8.622-25.457a3.134 3.134 0 00-2.215-2.019 10.407 10.407 0 005.511-3.564s11.5 33.11 11.59 33.322c1.65 4.254 2.073 4.892 3.911 4.892zm-12.438-14.509l-5.8-16.741-5.843 16.741z' />
         <path d='M166.758 150.705v.691h-9.847l-10.79-18.495h-4.57v13.924c0 4.092 3.251 3.88 4.57 3.88v.691h-13.616v-.691c1.178 0 4.146.159 4.146-3.827V132.21h9.047l-9.894-16.953a4.3 4.3 0 00-3.251-2.02v-.691h13.1c4.005 0 12.3.957 12.3 10.1 0 5.581-4.146 8.45-6.738 9.566 3.911 6.8 8.952 16.475 13.946 18.176a9.423 9.423 0 001.597.317zm-14.512-28.061c0-5.368-1.366-9.407-6.6-9.407h-5.315l9.659 16.848c1.361-1.329 2.256-3.402 2.256-7.441z' />
         <path d='M177.926 146.878c0 3.3 1.743 3.827 3.581 3.827v.691h-11.92v-.691c1.838 0 3.534-.532 3.534-3.827v-29.815c0-3.3-1.7-3.827-3.534-3.827v-.691h11.92v.691c-1.838 0-3.581.532-3.581 3.827z' />
         <path d='M218.54 112.546v.744c-2.12 0-5.748 1.754-5.748 7.759v30.346h-1.6l-20.5-32.047v23.544c0 6.006 3.628 7.759 5.748 7.759v.744h-12.106v-.744c2.262 0 5.748-1.594 5.748-7.706v-24.5l-1.13-1.807s-1.979-3.188-4.476-3.188v-.9h8.292l19.459 29.811v-21.364c0-6.112-3.581-7.707-5.842-7.707v-.744z' />
         <path d='M113.427 28.91l-14.1-14.1 14.1-14.1 14.1 14.1zm-10.864-14.1l10.864 10.864 10.864-10.864-10.864-10.864z' />
         <path d='M113.427 37.664l-14.1-14.1 14.1-14.1 14.1 14.1zm-10.864-14.1l10.864 10.864 10.864-10.864L113.427 12.7z' />
         <path d='M112.285 2.335h2.285V45.7h-2.285z' />
         <path d='M78.129 17.15l14.1 14.1-14.1 14.1-14.1-14.1zm10.864 14.1L78.129 20.386 67.265 31.25l10.864 10.864z' />
         <path d='M78.129 8.396l14.1 14.1-14.1 14.1-14.1-14.1zm10.864 14.1L78.129 11.632 67.265 22.496 78.129 33.36z' />
         <path d='M76.986.36h2.285v43.365h-2.285z' />
         <path d='M148.787 16.79l14.1 14.1-14.1 14.1-14.1-14.1zm10.864 14.1l-10.864-10.864-10.864 10.864 10.864 10.864z' />
         <path d='M148.787 8.036l14.1 14.1-14.1 14.1-14.1-14.1zm10.864 14.1l-10.864-10.864-10.864 10.864L148.787 33z' />
         <path d='M147.644 0h2.285v43.365h-2.285z' />
      </g>
   </svg>
);

export default SiteLogo;
