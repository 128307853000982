// @flow
import React from 'react';

import Link from '#/Link';
import { EventWrapper, EventText } from './style';

type Props = {|
   date: string,
   name: string,
   link: string,
|};

const EventsEvent = ({ date, name, link }: Props) => (
   <EventWrapper>
      <EventText>
         <span>{date}</span>
         <em> - </em>
         <span>{name}</span>
      </EventText>
      <Link target='_blank' rel='noopener noreferrer' href={link}>
         více
      </Link>
   </EventWrapper>
);

export default EventsEvent;
