// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export const StyledFooter: StyledComponent<
   {|
      children: Node,
   |},
   void,
   any,
> = styled.footer`
   @media ${queries.desktop} {
      padding: 180px 0 120px;
   }
   background-color: #1c1c1c;
   padding: 120px 0 80px;
`;

export const FooterContent: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   grid-column-start: 2;
   grid-column-end: 3;
   color: #fff;
`;
