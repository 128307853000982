// @flow
import React, { useState, useEffect } from 'react';

import Container from '#/Container';
import Location from '#/Location/Location';
import Socials from '#/Socials/Socials';
import Logo from '../SiteLogo';
import { HeaderWrapper, HeaderContent as Content } from './style';
import Nav from './Navigation/Navigation';
import Hamb from './Hamb/Hamb';

const Header = () => {
   const [active, setActive] = useState(false);
   useEffect(() => {
      if (document.body) {
         document.body.style.overflow = active ? 'hidden' : '';
      }
   }, [active]);

   function closeMenu() {
      setActive(false);
   }

   return (
      <HeaderWrapper>
         <Container>
            <Hamb active={active} setActive={setActive} />
            <Content active={active}>
               <Logo />
               <Nav closeMenu={closeMenu} />
               <Location />
               <Socials />
            </Content>
         </Container>
      </HeaderWrapper>
   );
};

export default Header;
