// @flow
import React from 'react';

import Container from '#/Container';
import { StyledFooter, FooterContent as Content } from './style';
import Upper from './Upper/Upper';
import Lower from './Lower/Lower';
import Partners from './Partners/Partners';

const Footer = () => (
   <StyledFooter>
      <Container>
         <Content>
            <Upper />
            <Partners />
            <Lower />
         </Content>
      </Container>
   </StyledFooter>
);

export default Footer;
