// @flow
import React from 'react';

import { StyledHamb } from './style';

type Props = {|
   active: boolean,
   setActive: (active: boolean | ((boolean) => boolean)) => void,
|};

const HeaderHamb = ({ active, setActive }: Props) => (
   <StyledHamb
      active={active}
      onClick={() => {
         setActive((prev) => !prev);
      }}
   >
      <span />
      <span />
      <span />
   </StyledHamb>
);

export default HeaderHamb;
