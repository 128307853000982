// @flow
import React from 'react';

import { StyledHeader, StyledPeriod } from './style';
import Heading from '#/Heading/Heading';
import Text from '#/Text';

type Props = {|
   title: string,
|};

const EventsHeader = ({ title }: Props) => (
   <StyledHeader>
      <Text>#zahradasavarin</Text>
      <Heading theme='light'>Program</Heading>
      <StyledPeriod>{title}</StyledPeriod>
   </StyledHeader>
);

export default EventsHeader;
