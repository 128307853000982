// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import Heading from '#/Heading/StyledHeading';
import queries from '%/mediaQueries';

export const LandingImages: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.desktop} {
      grid-template-columns: 1.5fr 1fr 0.8fr;
   }
   @media ${queries.tablet} {
      grid-template-columns: 1.5fr 1fr;
   }
   @media ${queries.tabletPlus} {
      display: grid;
      grid-template-rows: 1.6fr 1.8fr 0.4fr 0.4fr;
      grid-gap: 0 20px;
      margin-top: 120px;
      height: 800px;
      position: relative;
      grid-column-start: 2;
      grid-column-end: 3;
      & > div {
         &:last-child {
            @media ${queries.desktop} {
               display: inline-block;
            }
            display: none;
         }
      }
   }
   display: none;
`;

export const LandingHeading: StyledComponent<
   {|
      children: string,
   |},
   void,
   any,
> = styled(Heading)`
   position: absolute;
   top: 40px;
   right: 50%;
   transform: translate(70%, 0);
   z-index: 2;
`;
