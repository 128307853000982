// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import Heading from '#/Heading/StyledHeading';
import queries from '%/mediaQueries';

export const FoodWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   background-color: #fff;
   margin-bottom: 50px;
`;

export const FoodContent: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.desktop} {
      grid-template-columns: 1fr auto;
      align-items: start;
      grid-gap: 0 30px;
   }
   grid-gap: 30px 0;
   grid-template-columns: 1fr;
   display: grid;
   grid-column-start: 2;
   grid-column-end: 3;

   z-index: 2;
   ${Heading} {
      @media ${queries.desktop} {
         margin-top: 200px;
         margin-bottom: 0;
         grid-row: auto;
      }
      @media ${queries.tablet} {
         margin: 120px 0;
      }
      margin-top: 100px;
      margin-bottom: 60px;
      justify-self: center;
      grid-row: 1;
      max-width: 500px;
   }
`;
