// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import Heading from '#/Heading/StyledHeading';
import queries from '%/mediaQueries';

export const StyledLandingContent: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.mobile} {
      grid-column-start: 2;
      margin-top: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 200px;
      & > svg {
         width: 150px;
      }
      ${Heading} {
         margin: 70px 0;
      }
   }
   display: none;
`;
