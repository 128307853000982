import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
      font-family: 'Inter', sans-serif !important;
   }

   html, body {
      position: relative;

      width: 100%;
      min-width: 360px;
      max-width: 100%;
      height: 100%;
      scroll-behavior: smooth;
   }
`;
