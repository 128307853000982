// @flow
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export const EventWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.tabletPlus} {
      flex-direction: row;
      margin-bottom: 40px;
   }
   flex-direction: column;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 60px;
   em {
      @media ${queries.tabletPlus} {
         display: inline;
         font-style: normal;
      }
      display: none;
   }
   span {
      @media ${queries.tabletPlus} {
         display: inline;
      }
      display: block;
   }
`;

export const EventText: StyledComponent<
   {|
      children: string,
   |},
   void,
   HTMLHeadingElement,
> = styled.h4`
   @media ${queries.desktop} {
      font-size: 26px;
   }
   @media ${queries.tabletPlus} {
      padding-right: 20px;
      margin-bottom: 0;
      text-align: left;
   }
   text-align: center;
   margin-bottom: 30px;
   font-size: 20px;
   color: #fff;
   line-height: 1.4;
`;
