// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import { c1 } from '%/colors';
import Text from '#/Text';
import queries from '%/mediaQueries';

export const StyledHeader: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   background-color: ${c1};
   position: absolute;
   @media ${queries.tabletPlus} {
      transform: translate(0, -40%);
      left: 50%;
      right: auto;
      padding: 60px 140px 40px 60px;
      ${Text} {
         margin-bottom: 60px;
      }
   }
   transform: translate(50%, -60%);
   top: 0;
   right: 50%;
   padding: 35px 80px;

   color: #fff;
   ${Text} {
      margin-bottom: 20px;
   }
`;

export const StyledPeriod: StyledComponent<
   {|
      children: string,
   |},
   void,
   HTMLSpanElement,
> = styled.span`
   @media ${queries.mobile} {
      font-size: 16px;
      display: block;
      margin-top: 30px;
      text-align: center;
   }
   display: none;
`;
