// @flow
import React from 'react';

import { Navigation, NavItem } from './style';

type Props = {|
   closeMenu: () => void,
|};

const HeaderNavigation = ({ closeMenu }: Props) => (
   <Navigation>
      <NavItem onClick={closeMenu} href='#about'>
         O zahradě
      </NavItem>
      <NavItem onClick={closeMenu} href='#events'>
         Program
      </NavItem>
      <NavItem onClick={closeMenu} href='#contact'>
         Kontakt
      </NavItem>
   </Navigation>
);

export default HeaderNavigation;
