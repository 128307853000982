// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

export const ContactMapWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLAnchorElement,
> = styled.a`
   display: block;
   height: 600px;
   position: relative;
   overflow: hidden;
   & > div {
      height: 100%;
   }
`;
