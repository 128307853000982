// @flow
import React from 'react';

import { AboutWrapper, AboutContent } from './style';
import StyledHeading from '#/Heading/StyledHeading';
import Container from '#/Container';
import Text from '#/Text';

const About = () => (
   <AboutWrapper id='about'>
      <Container>
         <AboutContent>
            <StyledHeading top hash left theme='dark'>
               Nasajte čerstvý <br /> vzduch uprostřed <br /> města
            </StyledHeading>
            <Text>
               Objevte unikátní prostor, který jsme vytvořili pár kroků od
               Václavského náměstí. V Zahradě Savarin najdete skvělé jídlo,
               parádní drinky, vychlazené pivo a hlavně neopakovatelnou
               atmosféru. Přijďte zažít něco neokoukaného, co za pár měsíců zase
               zmizí. Inspirovali jsme se myšlenkou zahraničních ruin pubs a
               jako parta kamarádů přinášíme do centra Prahy ideální místo pro
               setkávání. Bílé ubrusy u nás nenajdete, ale originální dekorace a
               autentický zážitek ano. Chystáme nejrůznější akce, uslyšíte u nás
               svěží DJ sety od pražských rezidentů a do podzimu toho plánujeme
               mnohem víc. Zkrátka #centrumpatrinam, tak se stavte po práci s
               partou nebo kolegy najíst, napít a potkat se.{' '}
               <em>#nadrinknaprikopy</em>
            </Text>
         </AboutContent>
      </Container>
   </AboutWrapper>
);

export default About;
