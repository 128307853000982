// @flow
import { type Node } from 'react';
import styled, { css, type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';
import Heading from './Heading';

const pseudoStyles = css`
   content: '';
   display: inline-block;
   position: absolute;

   @media ${queries.desktop} {
      height: 10px;
      min-width: 140px;
   }
   @media ${queries.tablet} {
      height: 8px;
      min-width: 100px;
   }
   @media ${queries.tabletPlus} {
      ${({ left }) =>
         left
            ? css`
                 left: 0;
              `
            : css`
                 right: 0;
              `}
   }
   @media ${queries.mobile} {
      height: 6px;
      min-width: 80px;
      right: 50%;
      transform: translate(50%, 0);
   }
   background-color: ${({ theme }) => (theme === 'light' ? '#fff' : '#000')};
`;

const StyledHeading: StyledComponent<
   {|
      theme: 'light' | 'dark',
      children: Node,
      top?: boolean,
      bottom?: boolean,
      left: boolean,
      hash?: boolean,
   |},
   void,
   any,
> = styled(Heading)`
   position: relative;
   ${({ top }) =>
      top
         ? css`
              @media ${queries.desktop} {
                 padding-top: 45px;
              }
              @media ${queries.tablet} {
                 padding-top: 30px;
              }
              padding-top: 20px;
              &::before {
                 @media ${queries.tabletPlus} {
                    ${pseudoStyles};
                    top: 0;
                 }
              }
           `
         : css``}
   ${({ bottom, hash }) =>
      bottom && !hash
         ? css`
              @media ${queries.desktop} {
                 padding-bottom: 45px;
              }
              @media ${queries.tablet} {
                 padding-bottom: 30px;
              }
              padding-bottom: 20px;
              &::after {
                 ${pseudoStyles};
                 bottom: 0;
              }
           `
         : !bottom && hash
         ? css`
              @media ${queries.desktop} {
                 padding-bottom: 45px;
              }
              @media ${queries.tablet} {
                 padding-bottom: 30px;
              }
              padding-bottom: 20px;
              &::after {
                 ${pseudoStyles};
                 content: '#zahradasavarin';
                 font-size: 18px;
                 width: auto;
                 height: auto;
                 background: none;
                 bottom: 0;
              }
           `
         : css``}
   @media ${queries.tabletPlus} {
      text-align: ${({ left }) => (left ? 'left' : 'right')};
   }
   text-align: center;
`;

export default StyledHeading;
