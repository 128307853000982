// @flow
import React from 'react';

import { Wrapper, SocialLink } from './style';

const HeaderSocials = () => (
   <Wrapper>
      <SocialLink
         href='https://www.facebook.com/zahradasavarin'
         target='_blank'
         rel='noopener noreferrer'
      >
         <svg
            xmlns='http://www.w3.org/2000/svg'
            height='28'
            viewBox='0 0 16.363 32.724'
         >
            <path d='M13.376 5.434h2.987V.23a38.576 38.576 0 00-4.35-.23c-4.309 0-7.26 2.709-7.26 7.689v4.583H0v5.817h4.753v14.635h5.828V18.089h4.561l.724-5.817h-5.287V8.265c0-1.681.454-2.832 2.8-2.832z' />
         </svg>
      </SocialLink>
      <SocialLink
         href='https://www.instagram.com/zahradasavarin'
         target='_blank'
         rel='noopener noreferrer'
      >
         <svg
            xmlns='http://www.w3.org/2000/svg'
            height='28'
            viewBox='0 0 32.92 32.926'
         >
            <path d='M32.836 9.68a12.031 12.031 0 00-.765-3.994 8.434 8.434 0 00-4.82-4.818A12.062 12.062 0 0023.259.1C21.496.019 20.937 0 16.466 0s-5.03.019-6.786.1a12.035 12.035 0 00-3.994.762 8.034 8.034 0 00-2.92 1.9 8.107 8.107 0 00-1.9 2.914 12.064 12.064 0 00-.763 3.998c-.084 1.763-.1 2.322-.1 6.792s.019 5.03.1 6.786a12.03 12.03 0 00.766 3.994 8.433 8.433 0 004.818 4.818 12.064 12.064 0 003.994.765c1.756.077 2.316.1 6.786.1s5.03-.019 6.786-.1a12.028 12.028 0 003.994-.765 8.422 8.422 0 004.818-4.818 12.072 12.072 0 00.765-3.994c.077-1.756.1-2.316.1-6.786s-.017-5.03-.094-6.786zm-2.965 13.443a9.025 9.025 0 01-.566 3.055 5.462 5.462 0 01-3.126 3.122 9.057 9.057 0 01-3.055.566c-1.737.077-2.258.1-6.651.1s-4.921-.019-6.651-.1a9.02 9.02 0 01-3.055-.566 5.067 5.067 0 01-1.891-1.229 5.119 5.119 0 01-1.225-1.886 9.058 9.058 0 01-.57-3.055c-.077-1.737-.1-2.258-.1-6.651s.019-4.921.1-6.651a9.02 9.02 0 01.57-3.055 5 5 0 011.231-1.891 5.111 5.111 0 011.891-1.229 9.063 9.063 0 013.055-.566c1.737-.077 2.258-.1 6.651-.1s4.921.019 6.651.1a9.025 9.025 0 013.055.566 5.063 5.063 0 011.891 1.228 5.118 5.118 0 011.229 1.891 9.062 9.062 0 01.566 3.055c.077 1.737.1 2.258.1 6.651s-.02 4.909-.1 6.645zm0 0' />
            <path d='M16.466 8.008a8.458 8.458 0 108.458 8.458 8.46 8.46 0 00-8.458-8.458zm0 13.945a5.487 5.487 0 115.487-5.487 5.488 5.488 0 01-5.487 5.487zm0 0' />
            <path d='M27.235 7.673a1.975 1.975 0 11-1.975-1.975 1.975 1.975 0 011.975 1.975zm0 0' />
         </svg>
      </SocialLink>
   </Wrapper>
);

export default HeaderSocials;
