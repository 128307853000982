// @flow
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Container from '#/Container';
import {
   EventsWrapper,
   EventsContent,
   EventsList,
   EventBackground,
} from './style';
import Background from './Background/Background';
import Heading from '#/Heading/StyledHeading';
import Event from './Event/Event';
import Header from './Header/Header';

const Events = () => {
   const [{ title, events }, setData] = useState({ title: '', events: [] });

   useEffect(() => {
      axios('https://api.github.com/gists/3b39578ecaf4a8e42e8422b03c62d1ef')
         .then(({ data }) => {
            const json = data.files['events.json'];
            setData(JSON.parse(json.content));
         })
         .catch(() => {});
   }, []);

   return (
      <EventsWrapper id='events'>
         <EventBackground>
            <Background />
         </EventBackground>
         <Container>
            <EventsContent>
               <Heading left bottom theme='light'>
                  {title}
               </Heading>
               <EventsList>
                  {events.map((event, i) => (
                     <Event
                        key={i}
                        date={event.date}
                        name={event.name}
                        link={event.link}
                     />
                  ))}
               </EventsList>
            </EventsContent>
            <Header title={title} />
         </Container>
      </EventsWrapper>
   );
};

export default Events;
