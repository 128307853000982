// @flow
import React from 'react';

import Heading from '#/Heading/StyledHeading';
import Location from '#/Location/Location';
import Logo from '#/SiteLogo';
import { StyledLandingContent as Content } from './style';

const LandingContent = () => (
   <Content>
      <Logo />
      <Heading top bottom left theme='light'>
         Ostrov zážitků
         <br /> v srdci Prahy
      </Heading>
      <Location />
   </Content>
);

export default LandingContent;
