// @flow
import React from 'react';

import { PartnerWrapper } from './style';

type Props = {|
   name: Array<string>,
   spec: string,
|};

const FooterPartner = ({ name, spec }: Props) => (
   <PartnerWrapper multiple={name.length > 1}>
      <span>{spec}</span>
      {name.map((nm) => (
         <h6 key={nm}>{nm}</h6>
      ))}
   </PartnerWrapper>
);

export default FooterPartner;
