// @flow
import styled, { type StyledComponent } from 'styled-components';
import queries from '%/mediaQueries';

import { c2 } from '%/colors';

export const Navigation: StyledComponent<
   {|
      children: Node,
   |},
   void,
   any,
> = styled.nav`
   @media ${queries.desktop} {
      grid-gap: 50px;
   }
   @media ${queries.tabletPlus} {
      grid-template-columns: auto auto auto;
      margin-bottom: 0;
   }
   grid-template-columns: 1fr;
   grid-gap: 30px;
   display: grid;
   margin-bottom: 70px;
`;

export const NavItem: StyledComponent<
   {|
      children: string,
   |},
   void,
   HTMLAnchorElement,
> = styled.a`
   @media ${queries.desktop} {
      font-size: 16px;
   }
   @media ${queries.tabletPlus} {
      &:hover {
         color: ${c2};
      }
      transition: color 0.2s;
      font-size: 14px;
   }
   color: #fff;
   font-weight: 700;
   font-size: 18px;
   text-decoration: none;
   justify-self: center;
`;
