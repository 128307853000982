// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import { c2 } from '%/colors';
import queries from '%/mediaQueries';

export const Wrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 10px;
`;

export const SocialLink: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLAnchorElement,
> = styled.a`
   svg {
      height: 28px;
      @media ${queries.tablet} {
         height: 22px;
      }
      path {
         transition: fill 0.2s;
         fill: #fff;
      }
   }
   &:hover {
      path {
         fill: ${c2};
      }
   }
`;
