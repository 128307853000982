// @flow
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import queries from '%/mediaQueries';
import { BackgroundWrapper as Wrapper } from './style';

const LandingBackground = () => {
   const { image1, image2 } = useStaticQuery(graphql`
      query {
         image1: file(relativePath: { eq: "landing-bg.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 2400, quality: 25) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image2: file(relativePath: { eq: "XT3S0621-small.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `);
   const sources = [
      {
         ...image1.childImageSharp.fluid,
         media: queries.tabletPlus,
      },
      {
         ...image2.childImageSharp.fluid,
         media: queries.mobile,
      },
   ];
   return (
      <Wrapper>
         <Img
            imgStyle={{
               height: '100%',
            }}
            fluid={sources}
         />
      </Wrapper>
   );
};

export default LandingBackground;
