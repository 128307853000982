// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export type Props = {|
   children: Node,
|};

const Container: StyledComponent<Props, void, HTMLDivElement> = styled.div`
   @media ${queries.desktop} {
      grid-template-columns: auto 1030px auto;
   }
   @media ${queries.desktopLarge} {
      grid-template-columns: auto 1170px auto;
   }

   @media ${queries.tablet} {
      grid-template-columns: auto 95% auto;
   }
   grid-column-gap: 15px;
   display: grid;
   grid-template-columns: auto 1fr auto;
   width: 100%;
`;

export default Container;
