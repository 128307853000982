// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export const ImageWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.desktop} {
      grid-gap: 25px;
      transform: translate(0, -100px);
   }
   @media ${queries.tabletPlus} {
      grid-template-areas:
         'A .'
         'A C'
         'D C'
         'D E'
         'F E'
         'F .';
      grid-gap: 20px;
      align-items: center;
      justify-content: center;
      display: grid;
   }
   display: flex;
   flex-direction: column;
   align-items: center;
`;
