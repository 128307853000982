// @flow
import React from 'react';

import Heading from '#/Heading/StyledHeading';
import Logo from './Logo';
import { FooterUpperWrapper as Wrapper } from './style';

const FooterUpper = () => (
   <Wrapper>
      <Heading left top theme='light'>
         Myšlenku zahrady v život proměnili
      </Heading>
      <Logo />
   </Wrapper>
);

export default FooterUpper;
