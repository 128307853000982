// @flow
import styled, { type StyledComponent } from 'styled-components';

import { c2 } from '%/colors';
import queries from '%/mediaQueries';

const Link: StyledComponent<
   {|
      children: string,
   |},
   void,
   HTMLAnchorElement,
> = styled.a`
   @media ${queries.desktop} {
      padding: 15px 40px;
      font-size: 16px;
   }
   @media ${queries.tabletPlus} {
      &:hover {
         background-color: ${c2};
         color: #fff;
      }
      transition: color 0.2s, background-color 0.2s;
      border: 3px solid ${c2};
   }
   font-size: 14px;
   text-decoration: none;
   display: inline-block;
   color: ${c2};
   border: 2px solid ${c2};
   border-radius: 50px;
   padding: 10px 35px;
   font-weight: 700;
`;

export default Link;
