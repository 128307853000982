// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';
import Heading from '#/Heading/StyledHeading';

export const ContactUpper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   background-color: #fff;
`;

export const ContactUpperContent: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.desktop} {
      grid-template-columns: minmax(auto, 480px) minmax(auto, 500px);
   }
   @media ${queries.tablet} {
      grid-template-columns: minmax(auto, 480px) minmax(auto, 450px);
   }
   @media ${queries.tabletPlus} {
      grid-gap: 0 30px;
   }
   ${Heading} {
      max-width: 500px;
      justify-self: center;
   }
   grid-template-columns: 1fr;
   grid-column-start: 2;
   grid-column-end: 3;
   display: grid;
   grid-gap: 40px 0;
   align-items: center;
   justify-content: space-between;
`;
