// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export const LowerWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.desktop} {
      padding-top: 170px;
   }
   @media ${queries.tabletPlus} {
      flex-direction: row;
   }
   flex-direction: column;
   padding-top: 130px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   & > svg {
      @media ${queries.desktop} {
         width: 500px;
      }
      @media ${queries.tablet} {
         width: 400px;
      }
      @media ${queries.mobile} {
         margin-bottom: 40px;
      }
      width: 320px;
   }
`;
