// @flow
import React from 'react';

import Socials from '#/Socials/Socials';
import Logo from '#/SiteLogoInline';
import { LowerWrapper } from './style';

const FooterLower = () => (
   <LowerWrapper>
      <Logo />
      <Socials />
   </LowerWrapper>
);

export default FooterLower;
