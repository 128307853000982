// @flow
import React from 'react';

import Link from '#/Link';
import SiteLogo from '#/SiteLogo';
import { ContactCard as Card, ContactCardText as Text } from './style';

const ContactCard = () => (
   <Card>
      <SiteLogo />
      <Text>
         <span>Vnitroblok paláce Savarin</span>
         <span>Na Příkopě 852/10, Praha 1</span>
         <div>
            <span>Vstup z ulice Na Příkopě</span>
            {/* <span>Otevřeno po - ne / 11:00 - 22:00</span> */}
            <span>Dočasně uzavřeno</span>
         </div>
         <div>
            <a href='tel:+420725550385'>T.: 725 550 385</a>
            <a href='mailto:info@zahradasavarin.cz'>
               E.: info@zahradasavarin.cz
            </a>
         </div>
         <div>
            <p>
               Rezervace stolu pro 6 - 12 osob je možná emailem či telefonicky
               na výše uvedených kontaktech. Rezervace stolu ve stejný den je
               možná pouze telefonicky do 17:00.
            </p>
         </div>
         <div>
            <p>
               V případě zájmu o pronájem zahrady pro soukromou akci či firemní
               event nás kontaktujte níže.
            </p>
         </div>
      </Text>
      <Link href='mailto:info@zahradasavarin.cz'>Nezávazně poptat</Link>
   </Card>
);

export default ContactCard;
