// @flow
import { type Node } from 'react';
import styled, { css, type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export const StyledHamb: StyledComponent<
   {|
      children: Node,
      active: boolean,
   |},
   void,
   HTMLButtonElement,
> = styled.button.attrs(() => ({
   type: 'button',
}))`
   @media ${queries.mobile} {
      z-index: 10;
      outline: none;
      height: 24px;
      width: 30px;
      padding: 0;
      border: none;
      grid-column-start: 2;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: none;
      cursor: pointer;
      span {
         width: 30px;
         height: 4px;
         display: block;
         background-color: #fff;
         transition: transform 0.2s, opacity 0.2s;
         ${({ active }) =>
            active
               ? css`
                    &:first-child {
                       transform: translate(0, 10px) rotate(-45deg);
                    }
                    &:nth-child(2) {
                       opacity: 0;
                       transform: translate(30px, 0);
                    }
                    &:last-child {
                       transform: translate(0, -10px) rotate(45deg);
                    }
                 `
               : css``}
      }
   }
   display: none;
`;
