// @flow

export const size = {
   mobile: 480,
   tablet: 768,
   medium: 900,
   desktop: 1180,
   desktopLarge: 1260,
};

export default {
   mobileSmall: `only screen and (max-width: ${size.mobile}px)`,
   mobile: `only screen and (max-width: ${size.tablet - 1}px)`,
   toMedium: `only screen and (max-width: ${size.medium - 1}px)`,
   mediumMax: `only screen and (min-width: ${
      size.tablet + 1
   }px) and (max-width: ${size.medium - 1}px)`,
   mediumMin: `only screen and (min-width: ${size.medium}px)`,
   tablet: `only screen and (min-width: ${size.tablet}px) and (max-width: ${
      size.desktop - 1
   }px)`,
   tabletPlus: `only screen and (min-width: ${size.tablet}px)`,
   desktop: `only screen and (min-width: ${size.desktop}px)`,
   desktopLarge: `only screen and (min-width: ${size.desktopLarge}px)`,
};
