// @flow
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';
import { c2 } from '%/colors';

export const StyledLocation: StyledComponent<
   {|
      children: string,
   |},
   void,
   HTMLSpanElement,
> = styled.span`
   @media ${queries.tabletPlus} {
      font-size: 14px;
      text-align: left;
   }
   font-size: 16px;
   color: ${c2};
   line-height: 1.4;
   font-weight: 700;
   display: inline-block;
   text-align: center;
`;
