// @flow
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export const StyledFoodImageWrapper: StyledComponent<
   {|
      gridName: string,
      large?: boolean,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   grid-area: ${({ gridName }) => `${gridName}`};
   position: relative;
   overflow: hidden;
   max-height: 100%;
   @media ${queries.desktop} {
      width: 320px;
      height: 480px;
   }
   @media ${queries.tablet} {
      width: 280px;
      height: 400px;
   }
   @media ${queries.tabletPlus} {
      margin-bottom: 0;
      max-width: none;
   }
   width: 100%;
   max-width: 400px;
   height: 320px;
   margin-bottom: 40px;
`;

export const InnerWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   position: relative;
   height: 100%;
   &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      opacity: 0.2;
      z-index: 2;
      width: 100%;
      height: 100%;
   }
   & > div {
      &:first-child {
         height: 100%;
      }
   }
`;

export const TextWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   position: absolute;
   bottom: 40px;
   left: 40px;
   z-index: 3;
`;

export const FoodImageHeading: StyledComponent<
   {|
      children: string,
   |},
   void,
   HTMLHeadingElement,
> = styled.h5`
   @media ${queries.desktop} {
      font-size: 34px;
   }
   @media ${queries.tablet} {
      font-size: 30px;
   }
   font-size: 28px;
   color: #fff;

   line-height: 1.2;
`;

export const Hours: StyledComponent<
   {|
      children: string,
   |},
   void,
   HTMLSpanElement,
> = styled.span`
   color: #fff;
   display: block;
   margin-top: 5px;
   font-size: 18px;
`;

export const FoodLink: StyledComponent<
   {|
      children: string,
   |},
   void,
   HTMLAnchorElement,
> = styled.a`
   color: #fff;
   display: inline-block;
   font-size: 18px;
   font-weight: 500;
   margin-top: 25px;
   text-decoration: underline;
`;
