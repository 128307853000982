// @flow
import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { BackgroundWrapper } from './style';

const EventsBackground = () => {
   const { image } = useStaticQuery(graphql`
      query {
         image: file(relativePath: { eq: "events.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 2400, quality: 30) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `);
   return (
      <BackgroundWrapper>
         <Img fluid={image.childImageSharp.fluid} />
      </BackgroundWrapper>
   );
};

export default EventsBackground;
