// @flow
import React from 'react';

import { StyledLocation } from './style';

const Location = () => (
   <StyledLocation>
      Vnitroblok paláce Savarin
      <br />
      Na Příkopě 852/10, Praha 1
   </StyledLocation>
);

export default Location;
