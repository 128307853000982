// @flow
import React from 'react';

import Heading from '#/Heading/StyledHeading';
import Container from '#/Container';
import { FoodWrapper, FoodContent } from './style';
import Images from './Images/Images';

const Food = () => (
   <FoodWrapper>
      <Container>
         <FoodContent>
            <Images />
            <Heading left={false} top hash theme='dark'>
               Přijďte hladoví. Tohle vám naservírujeme
            </Heading>
         </FoodContent>
      </Container>
   </FoodWrapper>
);

export default Food;
