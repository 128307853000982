// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export const LandingWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.mobile} {
      position: relative;
   }
`;
