import React from 'react';

import Styles from '../styles';
import SEO from '#/seo';
import Header from '#/Header/Header';
import Landing from '../scenes/Landing/Landing';
import About from '../scenes/About/About';
import Events from '../scenes/Events/Events';
import Food from '../scenes/Food/Food';
import Contact from '../scenes/Contact/Contact';
import Footer from '../scenes/Footer/Footer';

const IndexPage = () => (
   <>
      <SEO title='Savarin' />
      <Styles />
      <Header />
      <Landing />
      <About />
      <Events />
      <Food />
      <Contact />
      <Footer />
   </>
);

export default IndexPage;
