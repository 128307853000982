// @flow
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { LandingImages as Images, LandingHeading as Heading } from './style';
import Image from './Image/Image';

const LandingImages = () => {
   const { image1, image2, image3 } = useStaticQuery(graphql`
      query {
         image1: file(relativePath: { eq: "landing1.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 520, quality: 70) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image2: file(relativePath: { eq: "landing2.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 350, quality: 70) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image3: file(relativePath: { eq: "landing3.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 300, quality: 70) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `);
   return (
      <Images>
         <Heading top bottom left theme='light'>
            Ostrov zážitků
            <br /> v srdci Prahy
         </Heading>
         <Image image={image1} start={1} end={4} />
         <Image image={image2} start={2} end={5} />
         <Image image={image3} start={2} end={3} />
      </Images>
   );
};

export default LandingImages;
