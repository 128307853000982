// @flow
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import queries from '%/mediaQueries';
import { ContactMapWrapper } from './style';

const ContactMap = () => {
   const { image1, image2 } = useStaticQuery(graphql`
      query {
         image1: file(relativePath: { eq: "map-snapshot.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 2000, quality: 60) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         image2: file(relativePath: { eq: "map-snapshot-small.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 500, quality: 85) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   `);
   const sources = [
      {
         ...image1.childImageSharp.fluid,
         media: queries.tabletPlus,
      },
      {
         ...image2.childImageSharp.fluid,
         media: queries.mobile,
      },
   ];

   return (
      <ContactMapWrapper
         target='_blank'
         rel='noopener noreferrer'
         href='https://goo.gl/maps/hQAsSUZr5fAujB2Y7'
      >
         <Img fluid={sources} />
      </ContactMapWrapper>
   );
};

export default ContactMap;
