// @flow
import React from 'react';
import GatsbyImg from 'gatsby-image';

import {
   StyledFoodImageWrapper as Wrapper,
   InnerWrapper,
   TextWrapper,
   FoodImageHeading as Heading,
   FoodLink,
   Hours,
} from './style';

type Props = {|
   gridName: string,
   image: any,
   text: string,
   href: string,
   hours: string,
|};

const FoodImage = ({ text, gridName, image, href, hours }: Props) => (
   <Wrapper gridName={gridName}>
      <InnerWrapper>
         <GatsbyImg fluid={image} />
         <TextWrapper>
            <Heading>{text}</Heading>
            <Hours>{hours}</Hours>
            <FoodLink target='_blank' rel='noopener noreferrer' href={href}>
               menu
            </FoodLink>
         </TextWrapper>
      </InnerWrapper>
   </Wrapper>
);

export default FoodImage;
