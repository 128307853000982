// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import queries from '%/mediaQueries';

export const FooterUpperWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.desktop} {
      grid-template-columns: 500px auto;
   }
   @media ${queries.tabletPlus} {
      grid-template-columns: minmax(auto, 500px) auto;
   }
   grid-template-columns: 1fr;
   display: grid;
   justify-content: space-between;
   align-items: center;
   grid-gap: 0 40px;
`;

export const LogoWrapper: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   padding-top: 20px;
   @media ${queries.mobile} {
      text-align: center;
      padding-top: 50px;
   }
   span {
      @media ${queries.mobile} {
         margin: 20px auto 0;
      }
      display: block;
      font-size: 18px;
      color: #fff;
      margin-top: 30px;
      max-width: 230px;
      line-height: 1.3;
      font-weight: 700;
   }
`;
