// @flow
import React from 'react';

import Reset from './reset';
import Global from './global';

const Styles = () => (
   <>
      <Reset />
      <Global />
   </>
);

export default Styles;
