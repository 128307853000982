// @flow
import { type Node } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import { c1 } from '%/colors';
import queries from '%/mediaQueries';

export const ContactCard: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.desktop} {
      padding: 50px 90px 70px;
   }
   @media ${queries.tablet} {
      padding: 40px 60px;
   }
   @media ${queries.tabletPlus} {
      transform: translate(0, 100px);
      text-align: left;
   }
   text-align: center;
   padding: 30px;
   background-color: ${c1};
   transform: translate(0, 50px);
   position: relative;
   z-index: 3;
   & > svg {
      @media ${queries.desktop} {
         width: 180px;
      }
      width: 140px;
   }
`;

export const ContactCardText: StyledComponent<
   {|
      children: Node,
   |},
   void,
   HTMLDivElement,
> = styled.div`
   @media ${queries.desktop} {
      margin: 70px 0;
      font-size: 20px;
   }
   margin: 50px 0;
   font-size: 16px;
   display: grid;
   grid-template-columns: 1fr;
   grid-gap: 30px 0;
   & > span {
      &:first-child {
         font-weight: 700;
      }
   }
   span,
   a {
      text-decoration: none;
      display: block;
      line-height: 1.5;
      color: #fff;
   }
   p {
      @media ${queries.desktop} {
         font-size: 18px;
      }
      font-size: 14px;

      color: #fff;
      line-height: 1.6;
      font-weight: 500;
      a {
         display: inline-block;
         font-weight: 700;
      }
   }
`;
